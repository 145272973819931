import React from "react";
import "./aboutus.css";

const AboutImageComponent = ({ image }) => {
  return (
    <>
      <div className="leftimageContainer">
        <img src={image} alt="rightImage" />
      </div>
    </>
  );
};

export default AboutImageComponent;
