// import { Box, Typography } from '@mui/material';
// import axios from "axios";
// import { privacy } from '../Apiconfig';
// import React, { useEffect, useState } from "react";

// const PrivacyPolicy = (props) => {
//   const [privacyData, setPrivacyData] = useState([]);
//   const [loder, setloader] = useState(false);
//   const gethandlePrivacy = async () => {
//     try {
//       setloader(true);
//       const response = await axios({
//         method: "get",
//         url: privacy,
//       });
//       console.log("responsePrivacy ---->", response);
//       if (response.data.responseCode === 200) {
//         setPrivacyData(response?.data?.Privacy_Policy);
//         setloader(false);
//       }
//     } catch (error) {
//       console.log("errorPrivacy---->", error);
//       setloader(false);
//     }
//   };

//   useEffect(() => {
//     gethandlePrivacy();
//   }, [props.navigation]);

//   return (
//     <>
//     <div>
//       <Box
//         sx={{
//           padding: "50px 10%",
//           "@media(max-width:600px)": { padding: "50px 5%" }
//         }}
//       >
//         <Typography
//           variant="h1"
//           sx={{
//             color: "#fff",
//             fontFamily: "Roboto",
//             fontSize: "60px",
//             fontStyle: "normal",
//             fontWeight: 700,
//             lineHeight: "normal",
//             "@media(max-width:600px)": { fontSize: "40px" }
//           }}
//         >
//           Privacy Policy
//         </Typography>
//         <Box>
//           {/* <Typography
//       variant="h2"
//       sx={{
//         marginTop: "70px",
//         marginBottom: "30px",
//         color: "#262626",
//         fontFamily: "Roboto",
//         fontSize: "40px",
//         fontStyle: "normal",
//         fontWeight: 500,
//         lineHeight: "normal",
//         "@media(max-width:600px)": { fontSize: "35px", marginTop: "15px" }
//       }}
//     >
//       Lorem Ipsum
//     </Typography> */}
//           <Typography
//             sx={{
//               color: "#fff",
//               fontFamily: "Readex",
//               fontSize: "18px",
//               fontStyle: "normal",
//               fontWeight: 400,
//               lineHeight: "normal",
//               marginTop: 3,
//               textAlign: "justify",
//             }}
//           >
//             {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur
//     Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur
//     Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur
//     Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur  */}

//             {privacyData?.policey}
//           </Typography>
//         </Box>
//         <Box>
//           {/* <Typography
//       variant="h2"
//       sx={{
//         marginTop: "70px",
//         marginBottom: "30px",
//         color: "#262626",
//         fontFamily: "Roboto",
//         fontSize: "40px",
//         fontStyle: "normal",
//         fontWeight: 500,
//         lineHeight: "normal",
//         "@media(max-width:600px)": { fontSize: "35px", marginTop: "15px" }
//       }}
//     >
//       Lorem Ipsum
//     </Typography> */}
//           <Typography
//             sx={{
//               color: "#fff",
//               fontFamily: "Readex",
//               fontSize: "18px",
//               fontStyle: "normal",
//               fontWeight: 400,
//               lineHeight: "normal"
//             }}
//           >
//             {/* Your text goes here */}
//           </Typography>
//         </Box>
//       </Box>
//     </div></>
//   );
// };

// export default PrivacyPolicy;

import { Box, Typography } from "@mui/material";
import axios from "axios";
import { privacy } from "../Apiconfig";
import React, { useEffect, useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import Header from "../components/header/Header";
import Footer from "../components/Footer/Footer";

function PrivacyPolicy() {
  const navigate = useNavigate();
  const [privacyData, setPrivacyData] = useState([]);
  const [loder, setloader] = useState(false);

  const gethandlePrivacy = async () => {
    try {
      setloader(true);
      const response = await axios({
        method: "get",
        url: privacy,
      });
      console.log("responsePrivacy ---->", response);
      if (response.data.responseCode === 200) {
        setPrivacyData(response?.data?.About_us_data);
        setloader(false);
      }
    } catch (error) {
      console.log("errorPrivacy---->", error);
      setloader(false);
    }
  };

  useEffect(() => {
    gethandlePrivacy();
  }, []);

  // console.log("mahesh ", privacyData.privacy)

  return (
    <div
      className=""
      style={{
        height: "100vh",
        background: "#010127",
        // paddingTop: "152px",
        // position:"sticky",
        color: "#fff",
      }}
    >
      <Header />
      <Box
        sx={{
          padding: "4% 10% 50px 10%",
          background: "#010127",
          color: "#fff",
          // "@media(max-width:600px)": { padding: "75px 5%" },
          "@media(max-width:2500px)": { padding: "75px 5%" },
          // marginTop:"60px",
          position: "sticky",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection:"row",
            alignItems: "center",
            justifyContent:"space-between"
          }}
        >
          <Box
            sx={{
              lineHeight: "normal",
              fontSize: "60px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
              position: "sticky",
            }}
          >
            <IoIosArrowBack onClick={() => navigate("/")} />
          </Box>
          <Typography
            variant="h1"
            sx={{
              color: "#fff",
              fontFamily: "Open Sans",
              fontSize: "60px",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "normal",
              "@media(max-width:600px)": { fontSize: "40px" },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
             
              gap: "10px",
              position: "sticky",
            }}
          >
            Privacy Policy
          </Typography>
          <Box>
            {/* lkjljl */}
          </Box>
        </Box>
        <Box>
          <Typography
            sx={{
              color: "#fff",
              fontFamily: "Readex",
              fontSize: "18px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "normal",
              marginTop: 3,
              textAlign: "justify",
            }}
          >
            {/* {privacyData?.policey} */}
            {privacyData?.map((data, index) =>
              data?.id === 1 ? <div key={index}>{data?.about_dis}</div> : null
            )}
          </Typography>
        </Box>
        <Box>
          <Typography
            sx={{
              color: "#fff",
              fontFamily: "Readex",
              fontSize: "18px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "normal",
            }}
          >
            {/* Your text goes here */}
          </Typography>
        </Box>
      </Box>
      <Box
      // sx={{
      //   position: "sticky",
      //   bottom: "0",
      // }}
      >
        <Footer />
      </Box>
    </div>
  );
}

export default PrivacyPolicy;
