import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { Conditions } from '../Apiconfig';
import axios from 'axios';
import { IoIosArrowBack } from "react-icons/io";

import { useNavigate } from 'react-router-dom';
import Header from '../components/header/Header';
import Footer from '../components/Footer/Footer';


const TermCondition = () => {
  const navigate = useNavigate();
  const [isTermsandcondition, setTermscondition] = useState([]);
  const [loader, setloader] = useState(false);
  console.log("gjfj--->", isTermsandcondition);
  const gethandletermsandcondition = async () => {
    try {
      setloader(true);
      const response = await axios({
        method: "get",
        url: Conditions,
      });
      console.log("terms and conditions -->12", response);
      if (response.data.responseCode === 200) {
        setTermscondition(response?.data?.About_us_data);
        setloader(false);
      }
    } catch (error) {
      console.log("error terms and conditions---->", error);
      setloader(false);
    }
  };
  useEffect(() => {
    gethandletermsandcondition();
  }, []);
  return (
    

      <div 
        className=""
        style={{
          height: "100vh",
          background: "#010127",
          // paddingTop: "152px",
          // position:"sticky",
          color: "#fff",
       
      }}>
           <Header/>
        <Box
         sx={{
          padding: "4% 10% 50px 10%",
          background: "#010127",
          color: "#fff",
          // "@media(max-width:600px)": { padding: "75px 5%" },
          "@media(max-width:2500px)": { padding: "75px 5%" },
          // marginTop:"60px",
          position: "sticky",
        }}
        >
            <Box
          sx={{
            display: "flex",
            flexDirection:"row",
            alignItems: "center",
            justifyContent:"space-between"
          }}
        >
          <Box
            sx={{
              lineHeight: "normal",
              fontSize: "60px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
              position: "sticky",
            }}
          >
            <IoIosArrowBack onClick={() => navigate("/")} />
          </Box>
          <Typography
            variant="h1"
            sx={{
              color: "#fff",
              fontFamily: "Open Sans",
              fontSize: "60px",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "normal",
              "@media(max-width:600px)": { fontSize: "40px" },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
             
              gap: "10px",
              position: "sticky",
            }}
          >
           Terms & Conditions
          </Typography>
          <Box>
            {/* lkjljl */}
          </Box>
        </Box>
          <Box>

            <Typography
              sx={{
                color: "#fff",
                fontFamily: "Readex",
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: 400,

                textAlign: "justify",
                marginTop: 3,
                lineHeight: "normal"
              }}
            >
              {/* {isTermsandcondition?.terms} */}
              {isTermsandcondition?.map((data, index) =>
              data?.id === 2 ? <div key={index}>{data?.about_dis}</div> : null
            )}
            </Typography>
          </Box>

        </Box>
        <Box 
       
      >
      <Footer/>
      </Box>
      </div>
  );
};

export default TermCondition;
