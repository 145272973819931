// import Login from "../Components/Login";
// import {
//   createBrowserRouter,
//   RouterProvider,
// } from "react-router-dom";
// import MainContain from "../Container/MainContain";
// import Home from "../Pages/Home";
// import History from "../Pages/History";
// import PrivacyPolicy from "../Pages/PrivacyPolicy";
// import TermCondition from "../Pages/TermCondition";
// import Text2Speak from "../Pages/Text2Speak";
// import WorkingHour from "../Pages/WorkingHour";
// import DashBoard from "../Pages/DashBoard";
// import Logout from "../Components/Logout";

//  const router = createBrowserRouter([
//   {
//     path: "/",
//     element: <Login/>
//   },
//   {
//     path: "/dashboard",
//     element: <DashBoard/>
//   },
//   // {
//   //   path: "/home",
//   //   element:<Home/> ,
//   // },
//   // {
//   //   path: "/history",
//   //   element: <History/>
//   // },
//   // {
//   //   path: "/privacypolicy",
//   //   element:<PrivacyPolicy/>
//   // },
//   // {
//   //   path: "/termcondition",
//   //   element:<TermCondition/>
//   // },
//   // {
//   //   path: "/text2speak",
//   //   element:<Text2Speak/>
//   // },
//   // {
//   //   path: "/workinghours",
//   //   element:<WorkingHour/>
//   // },
//   // {
//   //   path: "logout",
//   //   element: <Logout/>
//   // },
// ]);
// const Rounting = () => {
//   return (
//     <>
//     <RouterProvider router={router} />
//     </>

//   )
// }

// export default Rounting;
import React from "react";
import { Routes, Route } from "react-router-dom";
import Login from "../components/Login";
import DashBoard from "../Pages/DashBoard";
import Home from "../Pages/Home";
import History from "../Pages/History";
import PrivacyPolicy from "../Pages/PrivacyPolicy";
import TermCondition from "../Pages/TermCondition";
import Speak from "../Pages/Text2Speak";
import WorkingHour from "../Pages/WorkingHour";
import Logout from "../components/LogOut";
import Register from "../components/Register";
import HistoryList from "../Pages/HistoryList";
import AboutUs from "../components/AboutUS/AboutUs";
import ContactUs from "../Pages/ContactUs";

const Routing = () => {
  return (
    <Routes>
      <Route path="/" element={<Register />} />
      <Route path="/login" element={<Login />} />
      <Route path="/privacypolicy" element={<PrivacyPolicy />} />
      <Route path="/termcondition" element={<TermCondition />} />
      <Route path="/contactus" element={<ContactUs />} />
      <Route path="/dashboard/*" element={<DashBoard />}>
        <Route index element={<Home />} />
        <Route path="history" element={<History />} />
        <Route path="user-task-list" element={<HistoryList />} />
        {/* <Route path="privacypolicy" element={<PrivacyPolicy />} /> */}
        {/* <Route path="termcondition" element={<TermCondition />} /> */}
        <Route path="text2speak" element={<Speak />} />
        <Route path="workinghours" element={<WorkingHour />} />
        <Route path="logout" element={<Logout />} />
        <Route path="aboutus" element={<AboutUs />} />
      </Route>
    </Routes>
  );
};

export default Routing;
