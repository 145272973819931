import React, { useEffect, useState } from "react";
import { Tilt } from 'react-tilt';



const VideoComponent = () => {
  return (
    <div className="videobackgroundContainer"  data-aos="zoom-in-down">
       <Tilt options={{ max: 25, speed: 400 }} className='tilt-container'>
        {/* <div className="videobackground" > */}
        <video
          controls
          width="100%"
          height="100%"
          src="/images/social_media.mp4"
          type="video/mp4"
        ></video>
      {/* </div> */}
       </Tilt>
      
    </div>
  );
};

export default VideoComponent;
