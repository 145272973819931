// GoogleAuth.js (custom hook)

import { useEffect, useState } from 'react';

const SCOPES = 'https://www.googleapis.com/auth/calendar';

const useGoogleAuth = () => {
  const [authInstance, setAuthInstance] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);


    // Load the Google API client library

    const initClient =() => {
    window.gapi.load('client:auth2', async () => {
      try {
        // Initialize the Google API client with your credentials
        await window.gapi.client.init({
          apiKey: 'AIzaSyA5bshqOmUuH7dokP2BSXtFqOX1JAoTSfc',
          clientId: '766223578897-d2i89sfhil674elhs2l85sd84a0oo84l.apps.googleusercontent.com',
          discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'],
          scope: SCOPES,
        });

        // Get the auth instance
        const authInstance = window.gapi.auth2.getAuthInstance();
        setAuthInstance(authInstance);

        // Update authentication status
        setIsAuthenticated(authInstance.isSignedIn.get());

        // Listen for sign-in state changes
        authInstance.isSignedIn.listen((isSignedIn) => setIsAuthenticated(isSignedIn));
      } catch (error) {
        console.error('Error initializing Google API client:', error);
      }
    });
}

  useEffect(() => {
    if (window.gapi) {
      // Now you can safely use window.gapi
      window.gapi.load('client:auth2', initClient);
    }
  }, []);
  const signIn = () => {
    authInstance.signIn();
  };

  const signOut = () => {
    authInstance.signOut();
  };

  return { authInstance, isAuthenticated, signIn, signOut };
};

export default useGoogleAuth;
