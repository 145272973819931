import React, { useEffect } from "react";
import "./KeyFeatures.scss";
import { Box, Grid } from "@mui/material";
import AOS from "aos";
import "aos/dist/aos.css";


const KeyFeatures = ({ checkBoxData, title }) => {
  
     useEffect(() => {
       AOS.init({
         duration: 1000,
         easing: "ease-in-out",
         once: false,
       });
     }, []);
  return (
    <Box className="fullKeyFeatures">
      <Grid container spacing={4} >
        <Grid
          item
          xs={12}
          sm={12}
          md={5}
          className="keyleftGrid"
          data-aos="fade-right"
        >
          <div>
            <div className="keyFeaturesTitle">{title.title1}</div>
            <div className="keyFatures2">{title.title2}</div>
            <div className="keyFatures3">{title.title3}</div>
          </div>
        </Grid>

        <Grid item xs={12} sm={12} md={7}>
          <div
            className="keyRightGridPArent"
            data-aos="fade-up"
            data-aos-anchor-placement="top-center"
          >
            {checkBoxData.map((item) => (
              <Box className="keyRightGrid1">
                <Box pt={1.5}>
                  <img src="Checkbox.png" alt="check" />
                </Box>
                <Box>
                  <div className="keyRightGrid1T">{item.title}</div>
                  <div className="keyRightGrid1D">{item.description}</div>
                </Box>
              </Box>
            ))}
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};

export default KeyFeatures;
