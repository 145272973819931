import { Box, Typography ,Accordion,
  AccordionDetails, IconButton,
  AccordionSummary,} from '@mui/material';
import React,{useState,useEffect} from 'react';
import { Button } from "@mui/material";
import { styled } from "@mui/system";
import {useNavigate} from 'react-router-dom';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckIcon from "@mui/icons-material/Check";
import { Generatetask, getUserTasks, isCompleted } from "../Apiconfig";
import axios from "axios";
import toast from "react-hot-toast";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
// import { Button, TextField } from "@mui/material";
// import { styled } from "@mui/system";
// import CircularProgress from "@mui/material/CircularProgress";

const StyledButton = styled(Button)({
  background: "linear-gradient(121deg, #534fc2, #534fc2, #9391c9)",
  borderRadius: 30,
  color: "white",
  height: 48,
  padding: "0 30px",
  boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .15)",
  margin: "10px 10px",
});

const Home = () => {
  const [loading, setLoading] = useState(false); 
  const [myTasks, setMyTasks] = useState([]);
  const styles = {
    container:{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop:'40px'
      // minHeight: "100vh",
    },
    btnContainer:{
      marginTop:'20px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    heading: {
      textAlign: 'center', // Align text to the center
      maxWidth: '80%', // Limiting text width for better readability
    },
  }

  const navigate = useNavigate();
  const handleTask = () =>{
    navigate('/dashboard/workinghours');
  };
  const fetchHandler = async () => {
    const token = localStorage.getItem("accessToken");
    try {
      const response = await axios({
        method: "GET",
        url: getUserTasks,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response?.status === 200) {
        setMyTasks(response?.data);
      }
      console.log("response---fetchHandler->", response);
    } catch (error) {
      console.log("error---->", error);
    }
  };
  const isCompletedHandler = async (sub_task_id) => {
    try {
      setLoading(true);
      const token = localStorage.getItem("accessToken");
      const response = await axios({
        method: "PUT",
        url: isCompleted,
        params: {
          id: sub_task_id,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("isCompletedHandler---->", response);
      if (response?.data?.responseCode === 200) {
        setLoading(false);
        toast.success(response.data?.responseMessage || "Updated Successfully");
        await fetchHandler();
      }
 
    } catch (error) {
      setLoading(false);
      toast.error(
        error.response.data?.responsemessage || "Something Went Wrong"
      );
    }
  };
  useEffect(() => {
    fetchHandler();
    console.log(myTasks,"Dddddddddddd")
  }, []);

  const completedButton = () => {
    return (
      <Box
        style={{
          fontSize:'12px',
          padding: "10px",
          display: "flex",
          height: "28px",
          flexDirection: ":row",
          background:
          "#010026",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "30px",
          color:"#fff",
          boxShadow: "rgba(255, 105, 135, 0.15) 0px 3px",
        }}
      >
        <CheckIcon />
        <Typography variant="subtitle1" style={{ color: "black !important" }}>
          Completed
        </Typography>
      </Box>
    );
  };
  const RenderTask = () => {
    return (
      <Box >
        {myTasks?.map((task, taskIndex) => (
          <Accordion key={taskIndex} sx={{background: "linear-gradient(121deg, #534fc2, #534fc2, #9391c9)", width:"900px"}}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon  sx={{ color: '#fff' }}/>}
              aria-controls={`panel-${taskIndex}-content`}
              id={`panel-${taskIndex}-header`}
            >
              <Typography variant="h6" sx={{ color: "white  !important" }}>
                {task.title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div>
                {task.sub_tasks.map((subTask, subTaskIndex) => (
                  <div
                    key={subTaskIndex}
                    style={{ display: "flex", alignItems: "center",justifyContent:'space-between',marginTop:subTaskIndex==0? '0px':'20px' }}
                  >
                    <Typography variant="subtitle1" sx={{ color: "white  !important" }}>
                      {subTask.title}
                    </Typography>

                    {subTask?.is_completed ? (
                      completedButton()
                    ) : (
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <IconButton
                          style={{ color: "white" }}
                          onClick={() => isCompletedHandler(subTask?.sub_task_id)}
                        >

                          <CheckCircleIcon />
                        </IconButton>
                      </Box>
                    )}
                  </div>
                ))}
              </div>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    );
  };
  return (
<Box sx={styles.container}>
  {myTasks.length > 0 ? (
    <RenderTask />
  ) : (
    <Box sx={styles.heading}>
      <Typography variant='h3' sx={{ fontSize: { xs: '2.5rem', sm: '3rem', md: '4rem' } }}>Create Your Daily Task</Typography>
      <Typography variant='h5' sx={{ fontSize: { xs: '1rem', sm: '1.5rem', md: '2rem' } }}>Start Speak To the AI and Generate Tasks</Typography>
      <Box sx={styles.btnContainer}>
        <StyledButton onClick={handleTask}>Create Task</StyledButton>
      </Box>
    </Box>
  )}
</Box>

  );
};

export default Home;

