import React, { useState, useEffect } from "react";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import MicIcon from "@mui/icons-material/Mic";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  Typography,
} from "@mui/material";
import { Button, TextField } from "@mui/material";
import { styled } from "@mui/system";
import PauseIcon from "@mui/icons-material/Pause";
import { useLocation, useNavigate } from "react-router-dom";
import { Generatetask, getUserTasks, isCompleted } from "../Apiconfig";
import axios from "axios";
import toast from "react-hot-toast";
import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckIcon from "@mui/icons-material/Check";
// import { token } from "../Components";
const StyledButton = styled(Button)({
  background: "linear-gradient(121deg, #534fc2, #534fc2, #9391c9)",
  borderRadius: 30,
  color: "white",
  height: 48,
  padding: "0 30px",
  boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .15)",
  margin: "10px 10px",
});

const HistoryList = (props) => {
  const styles = {
    container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
     
    },
  };

  const [myTasks, setMyTasks] = useState([]);
  //Fetch Hanlder Functions
  const fetchHandler = async () => {
    try {
      const token = localStorage.getItem("accessToken");
      const response = await axios({
        method: "GET",
        url: getUserTasks,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response?.status === 200) {
        setMyTasks(response?.data);
      }
      console.log("response---fetchHandler->", response);
    } catch (error) {
      console.log("error---->", error);
    }
  };

  useEffect(() => {
    fetchHandler();
  }, []);

  const RenderTask = () => {
    return (
      <Box>
        {myTasks && myTasks.length > 0 ? (
          myTasks.map((task, taskIndex) => (
            <Accordion
              key={taskIndex}
              sx={{
                background: "linear-gradient(121deg, #534fc2, #534fc2, #9391c9)",
                color: "#fff",
                width: "900px",
                fontSize: "12px",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}
                aria-controls={`panel-${taskIndex}-content`}
                id={`panel-${taskIndex}-header`}
              >
                <Box
                  style={{
                    flexDirection: "column",
                    flex: 1,
                    paddingRight: "10px",
                  }}
                >
                  <Box
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography variant="subtitle1">{task?.day}</Typography>
                    <Typography variant="subtitle1">{task?.date}</Typography>
                  </Box>
                  <Typography variant="h6">{task?.title}</Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <div>
                  {task.sub_tasks.map((subTask, subTaskIndex) => (
                    <div
                      key={subTaskIndex}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginTop: subTaskIndex === 0 ? "0px" : "20px",
                      }}
                    >
                      <Typography
                        variant="subtitle1"
                        sx={{ color: "#fff  !important" }}
                      >
                        {subTask.title}
                      </Typography>
                    </div>
                  ))}
                </div>
              </AccordionDetails>
            </Accordion>
          ))
        ) : (
          <Typography variant="h6" sx={{ color: "#fff" }}>
            No Data Found
          </Typography>
        )}
      </Box>
    );
  };
  

  return (
    <>
      <Box sx={{paddingTop:"27px", marginLeft:"37px", paddingBottom:"37px"}}>
          <Typography variant="h5"  style={{fontWeight:"bold"}}>
          TASK HISTORY LIST
          </Typography>
        </Box>
      <Box sx={styles.container}>
        {myTasks && <RenderTask />}
        
        </Box>
    </>
  );
};

export default HistoryList;
