import React from "react";
import { Grid } from "@mui/material";
import NavigationBar from "../components/Navbar";
import SliderBar from "../components/SliderBar";
import Login from "../components/Login";
import MainContain from "../Container/MainContain";

import Box from '@mui/material/Box';


const DashBoard = () => {
 


  
  return (
    // <Grid container style={{ position: 'relative' }}>
    //   <Grid item xs={12}>
    //     <NavigationBar />
    //   </Grid>

    //   <Grid item xs={12}>
    //     <Grid container>
    //       <Grid item xs={12} md={2.5} style={{ marginTop: '50px' }}>
    //         <SliderBar />
    //       </Grid>
    //       <Grid item  md={9.5} >
    //         <MainContain />
    //       </Grid>
    //     </Grid>
    //   </Grid>
    // </Grid>
    <Box sx={{ display: "flex" }}>
      <NavigationBar/>
      <SliderBar />
    <MainContain/>

    </Box>
  );
};

export default DashBoard;
