import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
} from "@mui/material";
import { ListItemButton, ListItemIcon } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { RxHamburgerMenu } from "react-icons/rx";

import { IoMdHome } from "react-icons/io";
import { MdWorkOutline } from "react-icons/md";
import { RiSpeakFill } from "react-icons/ri";
import { VscHistory } from "react-icons/vsc";
import { MdOutlinePrivacyTip } from "react-icons/md";
import { TbAirConditioningDisabled } from "react-icons/tb";
import { LuLogOut } from "react-icons/lu";



const NavigationBar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const isTabletOrMobile = useMediaQuery("(max-width: 1024px)");

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  

  const list = () => (
    <div
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {[
          { text: "Home", path: "/dashboard", icon: <IoMdHome /> },
          {
            text: "Work Hours",
            path: "/dashboard/workinghours",
            icon: <MdWorkOutline />,
          },
          {
            text: "Text to Speak",
            path: "/dashboard/text2speak",
            icon: <RiSpeakFill />,
          },
          { text: "History", path: "/dashboard/history", icon: <VscHistory /> },
          { text: "History List", path: "/dashboard/user-task-list", icon: <VscHistory /> },
          {
            text: "Privacy",
            path: "/dashboard/privacypolicy",
            icon: <MdOutlinePrivacyTip />,
          },
          {
            text: "Terms & conditions",
            path: "/dashboard/termcondition",
            icon: <TbAirConditioningDisabled />,
          },
          {
            text: "Contact Us",
            path: "/dashboard/contactus",
            icon: <TbAirConditioningDisabled />,
          },

          { text: "Logout", path: "/dashboard/logout", icon: <LuLogOut /> },
        ].map((item, index) => (
          <ListItem key={item.text} disablePadding>
            <ListItemButton component={Link} to={item.path}>
              <ListItemIcon sx={{ color: "white" }}>
                {item.icon}
                {/* {index % 2 === 0 ? <InboxIcon /> : <MailIcon />} */}
              </ListItemIcon>
              <ListItemText
                primary={item.text}
                disableTypography
                style={{ fontWeight: "bold" }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <>
      <AppBar
        position="fixed"
        sx={{ background: "linear-gradient(121deg, #534fc2, #534fc2, #9391c9)", color: "white" }}
      >
        <Toolbar>
          {isTabletOrMobile ? ( // Render the MenuIcon based on screen size
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer(true)}
            >
              <RxHamburgerMenu />
            </IconButton>
          ) : (
            <img
              src="logo.png"
              alt="Logo"
              style={{ height: "75px",marginLeft:'-20px' }}
             
            />
            // <Typography varient={"h3"}>Logo</Typography>
          )}
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: "240px", // Set your desired width
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: "240px",
            boxSizing: "border-box",
            marginTop: "64px",
            backgroundColor: "#010026",
            color: "white", // Add margin from top
          },
        }}
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
      >
        {list()}
      </Drawer>
    </>
  );
};

export default NavigationBar;
