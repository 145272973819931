import React from "react";
import "./aboutus.css";
import { Box, Button } from "@mui/material";

const GoogleButton = ({ image }) => {
  const handleClick = () => {
    window.open(
      "https://chromewebstore.google.com/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn",
      "_blank"
    );
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <img
          src="/images/bg.png"
          alt="buttonImage"
          height="420px"
          width="400px"
        //   onClick={handleClick}
          style={{ marginTop: "10px", cursor: "pointer" ,position:"relative"}}
        />

        {/* <img
          src="/images/button.png"
          alt="rightImage"
          height="80px"
          width="80px"
          style={{ marginBottom: "10px" }}
        /> */}

        <Button
          sx={{
            height: "45px",
            width: "180px",
            backgroundColor: "#34466F",
            borderRadius: "18px",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            textTransform: "none",
            fontSize:"15px",
            fontWeight:"500",
            marginTop: "130px",
            color:"white",
            position:"absolute",
            border:".5px solid white",
            
          }}
          onClick={handleClick}
        >
          Download
        </Button>
      </Box>
    </>
  );
};

export default GoogleButton;
