import React from "react";
const styled = {
  width:{
    maxWidth: "542px",
    width: "100%",  
  },
  pb:{paddingBottom:"17px"},
}
const AboutDataComponent = ({
  topheading,
  firstheading,
  secondheading,
  text,
  img,
  isTextOnLeft,
}) => {
  return (
    <div className="alignCenter righttextDataContainer">
      <div
        className={isTextOnLeft ? "textOnLeft" : "rigthtextData"}
      >
        <img src={img} alt="right image" width="92px" height="92px" style={{borderRadius:"100%"}} />
        <h5 style={styled.pb}>{topheading}</h5>

        <h2>{firstheading}</h2>

        <h2>{secondheading}</h2>

        <p style={styled.width}>{text}</p>
      </div>
    </div>
  );
};

export default AboutDataComponent;
