// import logo from './logo.svg';
import "./App.css";
import React, { useEffect } from "react";
// import NavigationBar from './Components/Navbar';
import Rounting from "./Routes/Routes";
// import DashBoard from './Pages/DashBoard';
import { Toaster } from "react-hot-toast";
import AOS from "aos";
import "aos/dist/aos.css";



function App() {
  useEffect(() => {
    setTimeout(() => {
      AOS.init();
    }, 1000);
  }, []);

  return (
    <>
      <Toaster position="top-right" />
      <Rounting />
    </>
  );
}

export default App;
