export const ServerUrl = "https://py-aiappdeveloper.mobiloitte.io/api/v1";

// export const baseUrl = " 172.16.6.127:8000/api/v1 ";

export const socialLogin = ServerUrl + "/user/social-login/";
export const profilegupdate = ServerUrl + "/user/profile/update/";
export const profileget = ServerUrl + "/user/profile";
export const logouturl = ServerUrl + "/user/logout";
// export const privacy = ServerUrl + "/privacy-policy";
export const privacy = ServerUrl + "/about-us";

// export const Conditions = ServerUrl + "/terms-and-conditions";
export const Contactus = ServerUrl + "/contact-us/"; // 
export const Conditions = ServerUrl + "/about-us";
export const workinghourspost = ServerUrl + "/user-work-hours";
export const workinghourget = ServerUrl + "/user-work-hours";
export const Generatetask = ServerUrl + "/generate-tasks/";
export const Generatetaskget = ServerUrl + "/get-user-tasks/";
export const createUserTask = ServerUrl + "/create-user-tasks/";
export const getUserTasks = ServerUrl + "/get-user-tasks/";
export const isCompleted = ServerUrl + "/dump-tasks/";
export const history = ServerUrl + "/history/";
export const historytask = ServerUrl + "/history-tasks/"
