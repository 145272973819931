import React, { useRef, useEffect } from 'react';
// import Logo from 'logo_gif.gif'
import './imageComponent.css';

const ImageComponent = () => {
  const imgRef = useRef();

  useEffect(() => {
    const restartGif = () => {
        if (imgRef.current) {
            imgRef.current.src = '/logo_gif.gif'; 
          }
    };

    // Attach the event listener to the GIF
    if (imgRef.current) {
      imgRef.current.addEventListener('animationiteration', restartGif);
    }

    // Cleanup the event listener on component unmount
    return () => {
      if (imgRef.current) {
        imgRef.current.removeEventListener('animationiteration', restartGif);
      }
    };
  }, []);

  useEffect(() => {
    // Start the animation when the component mounts
    if (imgRef.current) {
      imgRef.current.src = '/logo_gif.gif'; 
    }
  }, []);

  return (
    <div className="image-container">
      <img
        ref={imgRef}
        alt="hiii"
        className="animated-gif"
      
      />
    </div>
  );
};

export default ImageComponent;