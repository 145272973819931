import React from "react";
import Rounting from "../Routes/Routes";
import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
const MainContain = () => {
  return (
    <Box sx={{  background: "#010026", minHeight: "100vh", width: "100vw", color:"white", marginTop:{xs:"56px",md:"64px"} ,padding:"8px"}}>
   <Outlet/>
    </Box>
  );
};

export default MainContain;
