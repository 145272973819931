import React, { useState, useEffect } from "react";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Button, Checkbox, Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import axios from "axios";
import { workinghourget } from "../Apiconfig";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import CircularProgress from "@mui/material/CircularProgress";
const daysOfWeek = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
  "Overall",
];

const StyledButton = styled(Button)({
  background: "linear-gradient(121deg, #534fc2, #534fc2, #9391c9)",
  borderRadius: 30,
  color: "white",
  height: 48,
  padding: "0 30px",
  boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .15)",
  margin: "20px 50px",
});

const TimePicker = styled("input")({
  width: "100px", // Default width for smaller devices
  padding: "8px",
  borderRadius: "4px",
  border: "1px solid #ccc",
  marginRight: "8px",
  "@media (min-width: 600px)": {
    width: "100px", // Adjust width for medium-sized devices
  },
  "@media (min-width: 960px)": {
    width: "120px", // Adjust width for larger devices
  },
  "@media (min-width: 1280px)": {
    width: "140px", // Adjust width for extra-large devices
  },
});

function WorkingHour({ props }) {
  const [showCreateTaskButton, setShowCreateTaskButton] = useState(false);
  const [loading, setLoading] = useState(false); // State to track loading

  const navigate = useNavigate();
  const [schedule, setSchedule] = useState(
    daysOfWeek.reduce((acc, day) => {
      acc[day] = {
        startTime: "",
        endTime: "",
        ...(day.toLowerCase() != "Overall" && { isChecked: false }),
      };
      return acc;
    }, {})
  );

  console.log("schedule-->", schedule);
  const handleTimeChange = (day, timeType, event) => {
    const newTimeType = timeType === "startTime" ? "startTime" : "endTime";

    setSchedule((prevSchedule) => ({
      ...prevSchedule,
      [day]: {
        ...prevSchedule[day],
        [newTimeType]: event.target.value,
      },
    }));
  };

  const handleCheckboxChange = (day) => {
    setSchedule((prevSchedule) => ({
      ...prevSchedule,
      [day]: {
        ...prevSchedule[day],
        isChecked: !prevSchedule[day].isChecked,
      },
    }));
  };

  const getHourListHandler = async () => {
    try {
      // setLoading(true);
      const token = localStorage.getItem("accessToken");
      const response = await axios({
        method: "GET",
        url: workinghourget,
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${token}`,
        },
      });
      console.log(response, "before");
      if (response?.data?.responseCode === 200) {
        const outputData = {};

        response.data?.responseData?.work_hours_data.forEach((item) => {
          const dayName = item.day.charAt(0).toUpperCase() + item.day.slice(1); // Capitalize the first letter
          const formattedItem = {
            isChecked: item.is_working_day,
            startTime: item.start_time === "None" ? "" : item.start_time,
            endTime: item.end_time === "None" ? "" : item.end_time,
          };
          outputData[dayName] = formattedItem;
        });

        setSchedule(outputData);

        console.log(schedule, "schedule");
        // getCategoryList();
        // setIsLoading(false);
        // toast.success(response?.data?.message);
      }
      // setIsLoading(false);
    } catch (err) {
      toast.error(err?.response?.data?.responseMessage);
      console.error(err.res);
      // setIsLoading(false);
    }
  };

  //post handler

  const handleworkingHour = async () => {

    const token = localStorage.getItem("accessToken");

    const overallDay = schedule.Overall;
    const isOverallDayComplete =
    overallDay.startTime !== "" && overallDay.endTime !== "";
    if (!isOverallDayComplete) {
      toast.error("Please select overall workhours.");
      return;
    }
    const validDays = Object.keys(schedule).filter((day) => {
      if (day === "overall") {
        return true;
      }
      const { startTime, endTime, isChecked } = schedule[day];
      return isChecked && startTime !== "" && endTime !== "";
    });

    if (validDays.length === 0) {
      // Display an error message or handle invalid input
      toast.error("Please select at least one day with start and end times.");
      return;
    }

    try {
      setLoading(true); 
      const workingHoursData = Object.keys(schedule)?.map((day) => ({
        day,
        is_working_day: schedule[day].isChecked,
        start_time: schedule[day].startTime,
        end_time: schedule[day].endTime,
      }));
      console.log("workingHoursData---+++", workingHoursData);
      const reponse = await axios({
        method: "POST",
        // url: workinghourspost,
        url: "https://py-aiappdeveloper.mobiloitte.io/api/v1/user-work-hours",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
      },
        data: {
          work_hours_data: workingHoursData,
          is_computer_task: true,
        },
      });
      console.log("handleworkinghour111----->", reponse);
      if (reponse.data.responseCode === 200) {

        toast.success(reponse.data?.responseMessage || "Working Hour updated successfully");
        navigate("/dashboard/text2speak")
        // setloader(false);
      }
    } catch (error) {
      console.log("errorwrokinghours--errror--->", error);
      toast.error(
        error.response.data?.responsemessage || "Something Went Wrong"
      );
      // setloader(false);
    } finally {
      setLoading(false); // Set loading to false after API call (whether successful or not)
    }
  };

  useEffect(() => {
    getHourListHandler();
  }, []);

  useEffect(() => {
    // Log the schedule state whenever it changes
    console.log("Current Schedule:", schedule);
  }, [schedule]);

  // const handleTask = () =>{
  //   navigate('/text-to-speak', { state: { selectedData: schedule } });
  // };

  const styles = {
    container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minHeight: "100vh", 
      "& h6": {
        fontSize: "16px",
      },
      dayRow: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "10px",
        width: "100%", // Make sure each row occupies full width
      },
      checkboxBox: {
        display: "flex",
        alignItems: "center",
        width: "50%",
        color:"white" // Adjust width for checkboxes
      },
      timePickerBox: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        width: "50%", // Adjust width for time pickers
      },
      h6Margin: {
        paddingBottom: "10px", // Margin bottom for Typography h6 variant
      },
    },
  };

  return (
    <>
       <Box
        sx={{ paddingTop: "27px", marginLeft: "37px", paddingBottom: "37px" }}
      >
        <Typography variant="h5" style={{ fontWeight: "bold" }}>
        WORK HOURS
        </Typography>
      </Box>
      <Box sx={styles.container}>
        <FormGroup>
          {daysOfWeek.map((day, index) => (
            <Box key={index}  sx={styles.dayRow}>
              <Box>
                {day !== "Overall" ? (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={schedule[day]?.isChecked}
                        onChange={() => handleCheckboxChange(day)}
                        sx={{color:"#fff"}} 
                      />
                    }
                    sx={styles.checkboxBox}
                    label={day}
                  />
                ) : (
                  <Typography variant="h6" sx={{margin:"-2px", padding:"10px 0px"}}>{day}</Typography>
                )}
              </Box>
              <Box sx={styles.timePickerBox}>
                <>
                  <TimePicker
                    type="time"
                    className="timePicker"
                    name="appt"
                    value={schedule[day]?.startTime=="00:00:00" ? "":schedule[day]?.startTime}
                    onChange={(event) =>
                      handleTimeChange(day, "startTime", event)
                    }
                  />

                  <TimePicker
                    type="time"
                    className="timePicker"
                    name="appt"
                    value={schedule[day]?.endTime=="00:00:00" ? "":schedule[day]?.endTime}
                    onChange={(event) =>
                      handleTimeChange(day, "endTime", event)
                    }
                  />
                </>
              </Box>
            </Box>
          ))}
         <StyledButton onClick={handleworkingHour}  >
            {loading ? <CircularProgress size={24} color="inherit" /> : 'Submit'}
          </StyledButton>
        </FormGroup>
      </Box>
    </>
  );
}

export default WorkingHour;

//   background: linear-gradient(121deg, #534fc2, #534fc2, #9391c9);
