import React from "react";
import "./header.scss";
import { animateScroll as scroll } from 'react-scroll';



export default function Header() {

  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  const handleClick = () => {
    window.open('https://chromewebstore.google.com/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn', '_blank');
  };
  return (
    <div className="headerContainer">
      <img src="/logo.gif" alt="my-gif"  onClick={scrollToTop}/>
      <button onClick={handleClick}>Download Now</button>
    </div>
  );
}
