import React, { useState, useEffect } from "react";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import MicIcon from "@mui/icons-material/Mic";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  Typography,
} from "@mui/material";
import { Button, TextField } from "@mui/material";
import { styled } from "@mui/system";
import PauseIcon from "@mui/icons-material/Pause";
import { useLocation, useNavigate } from "react-router-dom";
import { Generatetask, getUserTasks, isCompleted } from "../Apiconfig";
import axios from "axios";
import toast from "react-hot-toast";
// import { token } from "../components";
import EditIcon from "@mui/icons-material/Edit";
import CircularProgress from "@mui/material/CircularProgress";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckIcon from "@mui/icons-material/Check";
import useGoogleAuth from "../components/GoogleAuth";
import ApiCalendar from "react-google-calendar-api";

const StyledButton = styled(Button)({
  background: "linear-gradient(121deg, #534fc2, #534fc2, #9391c9)",
  borderRadius: 30,
  color: "white",
  height: 48,
  padding: "0 30px",
  boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .15)",
  margin: "10px 10px",
});

const Speak = (props) => {
  const config = {
    clientId:
      "766223578897-d2i89sfhil674elhs2l85sd84a0oo84l.apps.googleusercontent.com",
    apiKey: "AIzaSyA5bshqOmUuH7dokP2BSXtFqOX1JAoTSfc",
    scope: "https://www.googleapis.com/auth/calendar",
    discoveryDocs: [
      "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
    ],
  };
  const { isAuthenticated, authInstance } = useGoogleAuth(); // Use the GoogleAuth hook or component
  const [apiCalendar,setApiCalender] = useState(new ApiCalendar(config));

  // const [eventTitle, setEventTitle] = useState("");
  // const [eventDescription, setEventDescription] = useState("");
  // const [eventStartDate, setEventStartDate] = useState("2023-01-01T10:00:00");
  // const [eventEndDate, setEventEndDate] = useState("2023-01-01T11:00:00");

  const [calendor, setCalandor] = useState([]);



// const CreateEVENSt =()=>{
//   apiCalendar?.onLoad(createEventss);
// }

useEffect(() => {
  apiCalendar.onLoad(() => {
    // Now you can safely use the ApiCalendar instance
    // Check if this.gapi is loaded and available
    console.log("this.gapi:", apiCalendar.gapi);
  });
}, [apiCalendar]);

  const location = useLocation();
  const navigate = useNavigate();
  const selectedData = location.state ? location.state.selectedData : null;

  console.log(selectedData, "selecteddata");
  const [listeningText, setListeningText] = useState("");
 
  const [loading, setLoading] = useState(false);
  const {
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
    finalTranscript,
  } = useSpeechRecognition();

  useEffect(() => {
    if (listening) {
      setListeningText(finalTranscript);
    }
  }, [finalTranscript, listening]);

  const startListening = () => {
    setListeningText("");
    SpeechRecognition.startListening({ continuous: true });
  };

  const stopListening = () => {
    SpeechRecognition.stopListening();
  };

  const speakTranscript = () => {
    if ("speechSynthesis" in window) {
      const synth = window.speechSynthesis;
      const utterance = new SpeechSynthesisUtterance(listeningText);
      synth.speak(utterance);
    } else {
      console.error("Text-to-speech is not supported in this browser.");
    }
  };

  const styles = {
    container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      paddingTop: "50px",
      // height: "100vh",
    },
  };

  const handlegenratetask = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("accessToken");
      // 'Authorization': `Bearer ${token}`,
      const reponse = await axios({
        method: "POST",
        url: Generatetask,
        // headers: {
        //   Authorization: `Bearer ${userToken}`,
        // },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: {
          userSchedule: listeningText,
          calendar:calendor,
          
        },
      });
      if (reponse.data.responseCode === 200) {
        setLoading(false);
        toast.success(reponse.data?.responseMessage || "Created successfully");
        console.log("data--->", reponse?.data);
        // setloaer(false);
        navigate("/dashboard/history", {
          state: { responseData: reponse.data },
        });
      }
    } catch (error) {
      setLoading(false);
      console.log("errorrgentratetask---->", error);
      toast.error(
        error.response.data?.responsemessage || "Something Went Wrong"
      );
      // setloaer(false);
    }
  };

  const [myTasks, setMyTasks] = useState([]);

  //Fetch Hanlder Functions
  const fetchHandler = async () => {
    const token = localStorage.getItem("accessToken");
    try {
      const response = await axios({
        method: "GET",
        url: getUserTasks,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response?.status === 200) {
        setMyTasks(response?.data);
      }
      console.log("response---fetchHandler->", response);
    } catch (error) {
      console.log("error---->", error);
    }
  };

  //Completed Hanlder Functions
  
  useEffect(() => {
    fetchHandler();
  }, []);



  return (
    <>
      <Box
        sx={{ paddingTop: "27px", marginLeft: "37px", paddingBottom: "37px" }}
      >
        <Typography variant="h5" style={{ fontWeight: "bold" }}>
          TEXT TO SPEED
        </Typography>
      </Box>
      <Box sx={styles.container}>
        {browserSupportsSpeechRecognition ? (
          <Box style={{ color: "#fff" }}>
            <TextField
              sx={{
                marginBottom: "10px",
                color: "#fff",
                border: "1px solid rgb(83, 79, 194)",
                backgroundColor: "#fff",
                borderRadius: "10px",
                width: "750px",
              }}
              value={listeningText}
              multiline
              rows={10}
              variant="outlined"
              fullWidth
              placeholder="Say something......"
              onChange={(e) => setListeningText(e.target.value)}
            />
            <Box
              style={{
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
              }}
            >
              <StyledButton
                onClick={startListening}
                disabled={listening}
                style={{ cursor: "pointer", color: "#2196F3", margin: "5px" }}
              >
                <MicIcon />
              </StyledButton>
              <StyledButton
                onClick={stopListening}
                disabled={!listening}
                style={{ color: "#2196F3" }}
              >
                <PauseIcon />
              </StyledButton>
              {/* <StyledButton onClick={resetTranscript}>
                Reset Transcript
              </StyledButton> */}
              {/* <StyledButton onClick={speakTranscript} disabled={!listeningText}>
                Speak Transcript
              </StyledButton> */}
              <StyledButton onClick={handlegenratetask}>
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Submit"
                )}{" "}
                {/* Button text changes based on loading state */}
              </StyledButton>
            </Box>
          </Box>
        ) : (
          <p>Your browser does not support speech recognition.</p>
        )}
      </Box>


    
    </>
  );
};

export default Speak;
