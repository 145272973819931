import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, TextField, Tooltip } from "@mui/material";
import { Button } from "@mui/material";
import { styled } from "@mui/system";
import { useLocation, useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { createUserTask } from "../Apiconfig";
import axios from "axios";
import IconButton from "@mui/material/IconButton";
import toast from "react-hot-toast";
import CircularProgress from "@mui/material/CircularProgress";
import ApiCalendar from "react-google-calendar-api";

function History() {
  const [userTasks, setUserTasks] = useState([]);
  const [loading, setLoading] = useState(false);
  const StyledButton = styled(Button)({
    background: "linear-gradient(121deg, #534fc2, #534fc2, #9391c9)",
    borderRadius: 30,
    color: "white",
    height: 48,
    padding: " 30px",
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .15)",
    margin: "10px 50px",
  });
  const location = useLocation();
  const navigate = useNavigate();
  const responseData = location.state?.responseData;

  console.log(userTasks, "reposneData");
  const styles = {
    container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      // minHeight: "100vh",
    },
    styledAccordian: {
      background: "linear-gradient(121deg, #534fc2, #534fc2, #9391c9)",
      maxHeight: "400px",
      color: "#fff",
      overflowY: "auto",
      minWidth: "100% !important",
      width: "750px",

      // minWidth: "100%",
    },
  };
  const handleEdit = () => {
    console.log("ddd");
  };
  const handleDelete = () => {
    console.log("delete");
  };

  const config = {
    clientId:
      "766223578897-d2i89sfhil674elhs2l85sd84a0oo84l.apps.googleusercontent.com",
    apiKey: "AIzaSyA5bshqOmUuH7dokP2BSXtFqOX1JAoTSfc",
    scope: "https://www.googleapis.com/auth/calendar",
    discoveryDocs: [
      "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
    ],
  };
  const apiCalendar = new ApiCalendar(config);

  const createEventss = async (subEvent) => {
    try {
      const event = {
        summary: subEvent?.title,
        start: {
          dateTime: new Date(subEvent.start_time).toISOString(),
          timeZone: "UTC",
        },
        end: {
          dateTime: new Date(subEvent.start_time).toISOString(),
          timeZone: "UTC",
        },
      };
      apiCalendar.getEvent();
      apiCalendar
        .createEvent(event)
        .then((result) => {
          console.log("resultresult", result);
        })
        .catch((error) => {
          console.log("apiCalendar", error);
        });
    } catch (error) {
      console.error("Error creating event:", error);
    }
  };
  const createUserTasks = async () => {
    const isAuthenticated = await apiCalendar.handleAuthClick();
    console.log(isAuthenticated, "ssssss");
    if (!isAuthenticated) {
      console.error("User is not authenticated.");
      return;
    }

    const token = localStorage.getItem("accessToken");
    try {
      setLoading(true);
      const response = await axios({
        method: "post",
        url: createUserTask,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        // headers: {
        //   Authorization: `Bearer ${userToken}`,
        // },
        data: { user_Tasks: userTasks },
      });

      console.log("res----->>>>>", response);
      if (response?.data?.responseCode === 200) {
        setLoading(false);
        for (let i = 0; i < userTasks?.length; i++) {
          for (let j = 0; j < userTasks[i]?.sub_tasks?.length; j++) {
            await createEventss(userTasks[i]?.sub_tasks[j]);
          }
        }
        navigate("/dashboard");
        toast.success(
          response?.data?.responseMessage || "Created tasks successfully."
        );
      }
      // for (const task of myTasks) {
      //   for (const subTask of task.sub_tasks) {
      //     await saveEvent(task, subTask);
      //   }
      // }

      // setloaer(false);
    } catch (error) {
      console.log("errorr---->>>>", error);
      // setloaer(false);
      setLoading(false);
    } finally {
      setLoading(false); // Set loading to false after API call (whether successful or not)
    }
  };

  const handleChangeText = (text, childIndex, parentIndex) => {
    const updatedData = [...userTasks];
    console.log("kjdask", updatedData);
    console.log("kjdask", [...updatedData[parentIndex]?.sub_tasks]);

    const subTask = [...updatedData[parentIndex]?.sub_tasks];
    subTask[childIndex] = { ...subTask[childIndex], title: text };
    updatedData[parentIndex].sub_tasks = subTask;
    setUserTasks(updatedData);
    console.log(userTasks, "userrrrrrr");
  };

  useEffect(() => {
    setUserTasks(responseData?.responseData?.user_Tasks);
  }, [location.state]);
  const formatTime = (timeString) => {
    const [datePart, timePart] = timeString.split(" ");
    const [hours, minutes] = timePart.split(":");
    const time = new Date(`${datePart}T${timePart}Z`);
  
    const options = { hour: "2-digit", minute: "2-digit", hour12: true, timeZone: 'UTC' };
    const formattedTime = time.toLocaleTimeString("en-US", options);
    return formattedTime;
  };
  
  return (
    <>
      <Box
        sx={{ paddingTop: "27px", marginLeft: "37px", paddingBottom: "37px" }}
      >
        <Typography variant="h5" style={{ fontWeight: "bold" }}>
          HISTORY
        </Typography>
      </Box>

      <Box sx={styles.container}>
        <Box>
          {userTasks?.length > 0 ? (
            userTasks.map((task, taskIndex) => (
              <Accordion key={taskIndex} sx={styles.styledAccordian}>
                <AccordionSummary
                  style={{ color: "#fff" }}
                  expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}
                  aria-controls={`panel-${taskIndex}-content`}
                  id={`panel-${taskIndex}-header`}
                >
                  <Typography style={{ padding: "5px" }}>
                    {task.title}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ color: "#fff" }}>
                  {task.sub_tasks.map((subTask, subTaskIndex) => (
                    <div
                      key={subTaskIndex}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        color: "#fff !important",
                        marginBottom: "5px",
                      }}
                    >
                      <div>
                        <Typography>
                          {`${formatTime(subTask.start_time)} to ${formatTime(
                            subTask.end_time
                          )}`}
                        </Typography>
                      </div>
                      <div style={{ display: "flex" }}>
                        <TextField
                          sx={{
                            color: "#fff !important",
                            overflowY: "auto",
                            width: "650px",
                            marginBottom: "15px",
                            marginLeft: "25px",
                            border: "1px solid #fff",
                            outline: "none",
                            fontSize: "12px",
                            "& input": {
                              color: "#fff",
                            },
                          }}
                          value={subTask.title}
                          InputProps={{ style: { color: "#fff" } }}
                          onChange={(e) =>
                            handleChangeText(
                              e.target.value,
                              subTaskIndex,
                              taskIndex
                            )
                          }
                        />
                        <Box
                          style={{
                            marginLeft: "10px",
                          }}
                        >
                          <Tooltip title="Edit">
                            <IconButton
                              style={{
                                color: "white",
                                fontSize: "30px",
                                background: "#534fc287",
                              }}
                              onClick={() => handleEdit(subTask)}
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </div>
                    </div>
                  ))}
                </AccordionDetails>
              </Accordion>
            ))
          ) : (
            <Typography variant="h5" sx={{ color: "#fff" }}>
              No Data Found
            </Typography>
          )}

          {userTasks?.length > 0 && (
            <Box
              mt={3}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <StyledButton onClick={createUserTasks}>
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Submit"
                )}
              </StyledButton>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
}

export default History;
