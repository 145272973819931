import React, { useState } from "react";
import { Drawer, List, ListItem, ListItemText,Box } from "@mui/material";
import { Link, NavLink } from "react-router-dom";
import sidebarOptions from "../Models/SlidebarOptions";

// import InboxIcon from "@mui/icons-material/MoveToInbox";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import useMediaQuery from "@mui/material/useMediaQuery";
import NavigationBar from "./Navbar";
import { IoMdHome } from "react-icons/io";
import { MdWorkOutline } from "react-icons/md";
import { RiSpeakFill } from "react-icons/ri";
import { VscHistory } from "react-icons/vsc";
import { MdOutlinePrivacyTip } from "react-icons/md";
import { TbAirConditioningDisabled } from "react-icons/tb";
import { LuLogOut } from "react-icons/lu";

const SliderBar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const isDesktop = useMediaQuery("(min-width: 1025px)");
  const sidebarBackgroundColor = isDesktop ? "red" : "transparent"; // Set background color
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  return (
    <Box >
      {isDesktop && (
        <Drawer
          anchor="left"
          open={drawerOpen}
          onClose={toggleDrawer(false)}
          variant="permanent"
          sx={{
            width: "240px",
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: "240px",
              boxSizing: "border-box",
              marginTop: "64px", // Default margin top for larger screens
              "@media (max-width: 600px)": {
                marginTop: "56px", // Small devices
              },
              background: "linear-gradient(121deg, #534fc2, #534fc2, #9391c9)",
              color: "white",
            },
          }}
        >
          <List>
          {[
          { text: "Home", path: "/dashboard",icon:<IoMdHome  style={{ fontSize: "24px" }}/>
        },
          { text: "Work Hours", path: "/dashboard/workinghours",icon:<MdWorkOutline  style={{ fontSize: "24px" }}/>
        },
          { text: "Text to Speak", path: "/dashboard/text2speak",icon:<RiSpeakFill  style={{ fontSize: "24px" }}/>

        },
          { text: "History", path: "/dashboard/history",icon:<VscHistory  style={{ fontSize: "24px" }}/>},
          { text: "History List", path: "/dashboard/user-task-list", icon: <VscHistory  style={{ fontSize: "24px" }}/> },
      
          { text: "Privacy", path: "/dashboard/privacypolicy",icon:<MdOutlinePrivacyTip  style={{ fontSize: "24px" }}/>
        },
          {
            text: "Terms & conditions",
            path: "/dashboard/termcondition",
            icon:<TbAirConditioningDisabled  style={{ fontSize: "24px" }}/>


          },
          { text: "Logout", path: "/dashboard/logout",icon:<LuLogOut  style={{ fontSize: "24px" }}/>

        },
            ].map((item, index) => (
              <ListItem key={item.text} disablePadding>
                <ListItemButton component={NavLink} to={item.path} activeClassName="active" sx={{
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.4)", 
                },
              }}>
                  <ListItemIcon sx={{ color: "white" }}>
                    {item.icon}
                    {/* {index % 2 === 0 ? <InboxIcon /> : <MailIcon />} */}
                  </ListItemIcon>
                  <ListItemText
                    primary={item.text}
                    disableTypography
                    style={{ fontWeight: "bold" }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Drawer>
      )}
    </Box>
  );
};

export default SliderBar;
