import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Button } from "@mui/material";
import { styled } from "@mui/system";
import { logouturl } from '../Apiconfig';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const StyledButton = styled(Button)({
  background: "linear-gradient(121deg, #534fc2, #534fc2, #9391c9)",
  borderRadius: 30,
  color: "white",
  height: 48,
  padding: "0 30px",
  boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .15)",
  margin: "10px 10px",
});


function Logout() {
  const navigate =useNavigate()
  const styles = {
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh', 
    },
    card: {
      background: 'transparent',
    },
  };
  const handletokenremove = async () => {
    // await AsyncStorage.removeItem("accessToken");

    // setModalVisible(false);
    // Props?.navigation?.navigate("LoginSplash");
    try {
      // setloader(true);
      const userToken =  localStorage.getItem("accessToken");

      const response = await axios({
        method: "POST",
        url: logouturl,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      console.log("Logout successful--->", response);
      if (response.data.responseCode === 200) {
       localStorage.removeItem("accessToken");
       navigate("/")
        // setloader(false);

        // Props?.navigation?.dispatch(
        //   CommonActions.reset({
        //     index: 0,
        //     routes: [{ name: "StartSplash" }],
        //   })
        // );

        // Props?.navigation?.navigate("LoginSplash")
      }
    } catch (error) {
      // setloader(false);
      console.error("Error during logout:", error);
    }
  };
  return (
    <>

    <Box sx={styles.container}>
      <Card sx={styles.card}>
        <CardContent>
          <Typography variant="h6" gutterBottom sx={{color: "#fff"}}>
            Are you sure you want to logout?
          </Typography>
          <Box display="flex" justifyContent="space-evenly">
            {/* <Button variant="contained" color="primary" onClick={handleLogout}> */}
            <StyledButton onClick={handletokenremove}>
              Yes
            </StyledButton>
            {/* <Button variant="contained" color="secondary" onClick={handleCancel}> */}
            <StyledButton onClick={()=>  navigate(-1)}>
              No
            </StyledButton>
          </Box>
        </CardContent>
      </Card>
    </Box></>
  );
}

export default Logout
