import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import AboutDataComponent from "./AboutDataComponent";
import AboutImageComponent from "./AboutImageComponent";
import AOS from "aos";
import "aos/dist/aos.css";
import GoogleButton from "./GoogleButton";

const AboutUs = ({ textData, isTextOnLeft,contanterImage }) => {
  console.log("fdsafdsf",textData)
  useEffect(() => {
    AOS.init({
      duration: window.innerWidth > 768 ? 1000 : 500,
    });
  }, []);
  return (
    <div
      data-aos="fade-up"
    >
      <Grid
        container
        spacing={4}
        alignContent="center"
        className={
          isTextOnLeft ? "normalGridContainer" : "reverseGridContainer"
        }
      >
        {isTextOnLeft ? (
          <>
            <Grid item xs={12} sm={12} md={6}>
              <AboutImageComponent image={contanterImage} />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <AboutDataComponent
                topheading={textData.topheading}
                firstheading={textData.firstheading}
                secondheading={textData.secondheading}
                text={textData.text}
                img={textData.img}
                isTextOnLeft={isTextOnLeft}
              />
            </Grid>
          </>
        ) : (
          <>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Grid container className="reversegridContainerInner">
                <Grid item xs={6} className="disappergrid"></Grid>
                <Grid item xs={6} className="reversegridContainerInnerSecond">
                  <AboutDataComponent
                    topheading={textData.topheading}
                    firstheading={textData.firstheading}
                    secondheading={textData.secondheading}
                    text={textData.text}
                    img={textData.img}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              {textData?.type === "extention" ?  
              
              <GoogleButton  image={contanterImage}/>
              :
              <AboutImageComponent image={contanterImage} />}
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
};

export default AboutUs;
